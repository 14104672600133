import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import variable from "./variable";

class realisationUnit extends React.Component {
    render() {
        const { title, subtitle, description, role, image, order } = this.props;
        return (
            <Realisation order={order}>
                <div className="container-flex">
                    <RealisationDescription>
                        <div className="title-container mt0 mb10">
                            <h2 className="mt0 mb10">{title}</h2>
                        </div>
                        <h5 className="mt0 mb0">{subtitle}</h5>
                        <p>{description}</p>
                        <p className="subText mb0">Rôle : {role}</p>
                    </RealisationDescription>
                </div>
                <div className="realisation">
                    <img src={image} alt="cote pro" />
                </div>
            </Realisation>
        );
    }
}

const Realisation = styled.div`
    display: flex;
    jusitfy-content:space-between
    margin: 40px auto;
    margin-bottom: 80px;
    .container-flex {

        display: flex;
        align-items: flex-start;
        width: 30%;
        order: ${props => props.order};
    }

    .realisation {

        width: 70%;
        img {


            width: 100%;
            max-height: 550px;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 992px) {
        flex-direction: column;
        .realisation {
            width: 100%;
            order: 1;
        }
        .container-flex {
            width: 100%;
            order: 2;
        }
        hr {
            background-color: ${variable.bodyColor};
        }
    }
`;

const RealisationDescription = styled.div`
    background-color: ${variable.lightGrey};
    position: relative;
    z-index: 999;
    padding: 30px;
    border-radius: 25px;
    h2 {
        color: ${variable.primaryColor};
    }
    h5 {
        color: ${variable.secondaryColor};
        font-weight: normal;
    }
    .subText {
        color: ${variable.secondGrey};
    }
`;

realisationUnit.defaultProps = {
    subtitle: "",
    role: "",
    order: "",
};

realisationUnit.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string.isRequired,
    role: PropTypes.string,
    image: PropTypes.node.isRequired,
    order: PropTypes.oneOf(["", "1"]),
};

realisationUnit.defaultProps = {};

export default realisationUnit;
