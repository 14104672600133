import React from "react";

import styled from "styled-components";
import Wrapper from "../components/wrapper";
import ImageFront from "../components/ImageFront";

import Realisation from "../components/realisationUnit";
import { frontImageText, realisationData } from "../utils/realisationContent";
import Layout from "../components/layout";

const Equipe = () => (
    <Layout>
        <ImageFront
            surtitle={frontImageText.surtitle}
            title={frontImageText.title}
            span={frontImageText.span}
            subtext={frontImageText.subtext}
        />
        <Wrapper>
            <Container>
                <h1>Nos réalisations</h1>
                {realisationData.map(realisationDataItem => {
                    return (
                        <React.Fragment key={realisationDataItem.title}>
                            <Realisation
                                title={realisationDataItem.title}
                                subtitle={realisationDataItem.subtitle}
                                description={realisationDataItem.description}
                                role={realisationDataItem.role}
                                image={realisationDataItem.image}
                                order={realisationDataItem.order}
                            />
                            <hr />
                        </React.Fragment>
                    );
                })}
            </Container>
        </Wrapper>
    </Layout>
);
const Container = styled.section`
    padding-top: 20px;
    hr {
        border: 1px solid #f5f5f5;
        margin-bottom: 80px;
        margin-top: 0px;
    }
    hr:last-child {
        display: none;
    }
`;

export default Equipe;
