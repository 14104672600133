import cotepro2 from "../images/coteprov2.png";
import ELSAN from "../images/elsan.png";
import easyVillage from "../images/easy-village.png";

export const frontImageText = {
    surtitle: "Projets",
    title: "Découvrez nos",
    span: "réalisations",
    subtext: "Nous réalisons des projets variés, notre expertise permet de répondre aux besoin du groupe La Poste ",
};
export const realisationData = [
    {
        title: "ELSAN",
        subtitle: "Groupe La Poste",
        description:
            "ADEL - Assistant Digital ELSAN est une application mobile permettant au patient d'avoir l'ensemble des informations liées à son hospitalisation sur son téléphone / sa tablette. Elle regroupe le contenu lié à l'établissement et les différents services utiles, des articles en lien avec son intervention, et une notion de parcours lui présentant les différentes étapes que ce soit avant, ou après, détaillant même le jour J.",
        role: "Dev Front",
        image: ELSAN,
        order: "",
    },
    {
        title: "Service +",
        subtitle: "Groupe La poste",
        description:
            "Service + est la solution développée par le groupe la Poste pour développer sa relation avec les professionnels. Nous avons notamment intégré des services tels que l’envoi de Courrier, LR (avec ou sans Accusé de Réception), ainsi que la Signature électronique. Ainsi les professionnels membres de COTE PRO peuvent faire signer et envoyer des contrats sans se déplacer.",
        role: "Dev Back, Dev Front, UI/UX",
        image: cotepro2,
        order: "1",
    },
    {
        title: "Easy Village",
        subtitle: "Groupe La Poste",
        description:
            "Easy Village est une application à destination d'écosystèmes (immeuble, campus, quartier...). Le but étant de proposer la gestion de divers objets et services connectés. Nous pourrons par exemple consulter l'état de notre boite au lettre connectée ou simuler via un service une présence chez soi lorsque nous partons en vacance (programmer l'ouverture de ses volets, l'allumage de certaines lampes etc). Toutes les données collectées par ces objets et services peuvent être disposées sur un dashboard.",
        role: "Dev Front",
        image: easyVillage,
        order: "",
    },
];
